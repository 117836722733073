import React from "react";
import { ExistingOrToCreateItem } from "../../../lib/manage-kontent-item";
import "./style.scss";

type PreviewModalProps = {
  existingItems: ExistingOrToCreateItem[];
  contentItemsToCreate: ExistingOrToCreateItem[];
  handleRequestClose: () => void;
  currentLang: string;
  languages: string[];
  overwrite: boolean;
};

export const PreviewModal: React.FC<PreviewModalProps> = ({
  existingItems,
  contentItemsToCreate,
  handleRequestClose,
  currentLang,
  languages,
  overwrite,
}) => {
  return (
    <div className="preview-modal-parent">
      <button className="close" onClick={handleRequestClose} />
      <div className="modal">
        <div className="title-area">
          <p className="u-spacing-l body-regular">
            Following language variants will be copied from source{" "}
            <strong>{currentLang}</strong> to the following language(s):{" "}
            <strong>{languages.join(", ")}</strong>.
          </p>
          <p className="u-spacing-l body-regular">
            {overwrite
              ? "For existing language variants a new draft will be created."
              : "Existing language variants will be skipped."}
          </p>
        </div>
        <div className="preview-grid">
          <div className="column">
            <p className="u-spacing-l body-medium-emphasis">To be created:</p>
            {contentItemsToCreate.map((item) => (
              <p className="body-regular" key={item.id}>
                {item.name} - {item.language}
              </p>
            ))}
          </div>
          <div className="column">
            <p className="u-spacing-l body-medium-emphasis">
              Existing language variants:
            </p>
            {existingItems.map((item) => (
              <p className="body-regular" key={item.id}>
                {item.name} - {item.language}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
