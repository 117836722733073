import React, { useEffect, useState } from "react";
import { CopyStatus } from "..";
import {
  ExistingOrToCreateItem,
  ManageKontentItem,
} from "../../../lib/manage-kontent-item";
import { useClientContext } from "../../ClientProvider";
import "./style.scss";

type ActionsProps = {
  selectedLanguages: string[];
  overwrite: boolean;
  setStatus: (val: CopyStatus) => void;
  setExistingItems: (items: ExistingOrToCreateItem[]) => void;
  setContentItemsToCreate: (items: ExistingOrToCreateItem[]) => void;
};

let errorTimer: any;
let successTimer: any;

export const Actions: React.FC<ActionsProps> = ({
  selectedLanguages,
  overwrite,
  setStatus,
  setExistingItems,
  setContentItemsToCreate,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const { client, currentLang, currentItem } = useClientContext();
  const itemId = currentItem && currentItem.id;

  useEffect(() => {
    if (error) {
      clearTimeout(errorTimer);
      errorTimer = setTimeout(() => {
        setError(null);
      }, 5000);
    }

    return () => clearTimeout(errorTimer);
  }, [error]);

  useEffect(() => {
    if (success) {
      clearTimeout(successTimer);
      successTimer = setTimeout(() => {
        setSuccess(null);
      }, 5000);
    }

    return () => clearTimeout(successTimer);
  }, [success]);

  return (
    <div className="actions-parent">
      {error && (
        <span className="status status--validation-failed">{error}</span>
      )}
      {success && (
        <span className="status status--filled-correctly">{success}</span>
      )}
      <div className="actions-buttons">
        <button
          className="btn btn--secondary"
          disabled={selectedLanguages.length === 0}
          onClick={handlePreview}
        >
          Preview
        </button>
        <button
          className="btn btn--primary"
          disabled={selectedLanguages.length === 0}
          onClick={handleRecursiveCopy}
        >
          Copy language(s)
        </button>
      </div>
    </div>
  );

  async function handleRecursiveCopy() {
    const manageKontentItem = new ManageKontentItem();
    if (client && currentLang && itemId) {
      setStatus("loading");
      try {
        const result = await manageKontentItem.copyRecursively({
          client,
          currentLang,
          requestedLangs: selectedLanguages,
          itemId,
          createNewVersionForExistingItems: overwrite,
          isPreview: false,
        });
        const amount = result.createdLanguageVariants.length;
        setSuccess(
          `Created ${amount} language ${
            amount === 1 ? "variant" : "variants"
          } from ${currentLang} into ${selectedLanguages.join(", ")}`
        );
        setStatus("idle");
      } catch (error: any) {
        setStatus("idle");
        console.error(error);
        setError(error.message);
      }
    }
  }

  async function handlePreview() {
    const manageKontentItem = new ManageKontentItem();
    if (client && currentLang && itemId) {
      try {
        setStatus("loading");
        const result = await manageKontentItem.copyRecursively({
          client,
          currentLang,
          requestedLangs: selectedLanguages,
          itemId,
          createNewVersionForExistingItems: overwrite,
          isPreview: true,
        });
        setExistingItems(result.existingItems);
        setContentItemsToCreate(result.contentItemsToCreate);
        setStatus("preview");
      } catch (error: any) {
        setStatus("idle");
        console.error(error);
        setError(error.message);
      }
    }
  }
};
