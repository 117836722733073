import { Main } from "./components/Main";
import { ClientProvider } from "./components/ClientProvider";
import "./lib/custom-element-v2.css";
import React from "react";

export const App: React.FC = () => {
  return (
    <ClientProvider>
      <Main />
    </ClientProvider>
  );
};

export default App;
