import React from "react";
import { useClientContext } from "./ClientProvider";
import { DeepCopy } from "./deep-copy";
import "./Main.scss";

export const Main: React.FC = () => {
  const { status } = useClientContext();
  return (
    <div
      className="container"
      data-disabled={status === "disabled" ? "" : undefined}
    >
      {status === "enabled" && <DeepCopy />}
    </div>
  );
};
