import { ManagementClient } from "@kontent-ai/management-sdk";

export function getManagementClient(
  projectId?: string,
  apiKey?: string
): ManagementClient | undefined {
  if (projectId && apiKey) {
    return new ManagementClient({
      environmentId: projectId,
      apiKey: apiKey,
    });
  }

  return undefined;
}
