import React from "react";
import "./style.scss";

type SelectOverwriteProps = {
  handleChange: (val: boolean) => void;
  value: boolean;
};

export const SelectOverwrite: React.FC<SelectOverwriteProps> = ({
  handleChange,
  value,
}) => {
  return (
    <div className="overwrite-parent">
      <p className="u-spacing-l body-medium-emphasis">
        Create new versions for existing translations?
      </p>
      <p className="u-spacing-l body-small">
        If you select &quot;Yes&quot;, a new draft will be created for each
        translation that already exists. If you select &quot;No&quot; the
        existing translations will remain untouched
      </p>
      <div className="option__pane">
        <input className="option__input--hidden" />
        <label
          onClick={() => selectValue(false)}
          className={`option option--is-radio-button ${
            !value && "option--is-selected"
          }`}
        >
          <span className="option__label">No</span>
        </label>
      </div>
      <div className="option__pane">
        <input className="option__input--hidden" />
        <label
          onClick={() => selectValue(true)}
          className={`option option--is-radio-button ${
            value && "option--is-selected"
          }`}
        >
          <span className="option__label">Yes</span>
        </label>
      </div>
    </div>
  );

  function selectValue(val: boolean) {
    if (val === value) return;
    handleChange(val);
  }
};
