import { ManagementClient } from "@kontent-ai/management-sdk";

export type Language = {
  codename: string;
  name: string;
};

export async function getLanguages(
  client: ManagementClient,
  currentLanguage: string
): Promise<Language[]> {
  const languages = await client.listLanguages().toPromise();
  const filteredList = languages.data.items.filter(
    (item) => item.codename !== currentLanguage && item.isActive
  );

  return filteredList.map(
    (m) =>
      ({
        codename: m.codename,
        name: m.name,
      } as Language)
  );
}
